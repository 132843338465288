/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
.bannerBackground {
    background-color: #282828;
}

.bannerBackgroundImage {
    width: 100%;
    height: auto;
}

.bannerHeading {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    color: #FCFCF9;
    margin-top: 10rem;
}

.bannerHeadingTagLine {
    font-family: Poppins;
    font-style: normal;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    color: #FCFCF9;
}

.bannerLearnMoreBtn {
    background-color: #FCFCF9;
    border: 1px solid #FCFCF9;
    color: #383839;
    border-radius: 0;
    font-family: Poppins;
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
}

.bannerLearnMoreBtn:hover {
    background-color: #282828;
    border: 1px solid #FCFCF9;
    color: #FCFCF9;
}

.bannerBenefitsContainer {
    justify-content: end;
}

.right-0 {
    right: 0;
}

.approvalOnDemand {
    width: 250px;
    height: 200px;
    background-color: #8C412D;
    padding: 40px 40px 24px 40px;
}

.noAgencyOverlays {
    width: 250px;
    height: 200px;
    background-color: #953900;
    padding: 40px 40px 24px 40px;
}

.fairlending {
    width: 250px;
    height: 200px;
    background-color: #BF4900;
    padding: 40px 40px 24px 40px;
}

.approvalOnDemand p,
.noAgencyOverlays p,
.fairlending p {
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    color: #FCFCF9;
}

@media screen and (min-width: 320px) and (max-width: 350.7px) {
    .approvalOnDemand,
    .noAgencyOverlays,
    .fairlending {
        height: 110px !important;
        padding: 15px 15px 8px 19px !important;
    }
}

@media screen and (max-width: 767.7px) {
    .bannerHeading {
        margin-top: 7rem;
    }

    /* .bannerBackgroundImage {
        height: 592px;
    } */

    .approvalOnDemand,
    .noAgencyOverlays,
    .fairlending {
        width: 33.33% !important;
        height: 120px;
        display: inline-block;
        padding: 20px 20px 8px 24px;
        vertical-align: bottom;
    }
}

@media screen and (min-width: 768px) {
    .bannerBackgroundImage {
        object-fit: fill;
        object-position: center;
    }

    .bannerBenefitsContainer {
        display: flex;
        gap: 0!important;
    }
}

@media screen and (min-width: 768px) and (max-width: 991.7px) {
    .bannerHeading {
        margin-top: 5rem;
    }
}

@media screen and (max-width: 991.7px) {
    .approvalOnDemand,
    .noAgencyOverlays,
    .fairlending {
        width: 125px;
        height: 120px;
        padding: 20px 20px 8px 24px;
    }

    .approvalOnDemandIcon,
    .noAgencyOverlaysIcon,
    .fairlendingIcon {
        width: 40px;
        height: 44.4px;
    }

    .approvalOnDemand p,
    .noAgencyOverlays p,
    .fairlending p {
        font-size: 11px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199.7px) {
    .approvalOnDemand,
    .noAgencyOverlays,
    .fairlending {
        width: 150px;
        height: 125px;
        padding: 20px 20px 8px 24px;
    }

    .approvalOnDemandIcon,
    .noAgencyOverlaysIcon,
    .fairlendingIcon {
        width: 40px;
        height: 40px;
    }

    .approvalOnDemand p,
    .noAgencyOverlays p,
    .fairlending p {
        font-size: 13px;
    }
}

@media screen and (min-width: 1200px) {
    /* .bannerBackgroundImage {
        height: 600px;
    } */
    .bannerHeading {
        font-size: 2.5rem;
    }
}


@media screen and (min-width: 1400px) {
    /* .bannerBackgroundImage {
        height: 700px;
    } */
    .bannerHeading {
        font-size: 3.5rem;
        /* margin-top: 12.5rem; */
    }
}
