/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
#termsAndConditionsContainer .terms_image {
    background-image: url("https://resources.swmc.com/swmc-images/TermsandConditions/bg.jpg") !important;
}

#termsAndConditionsContainer .angelaiDisclaimer {
    text-align: justify;
    word-wrap: break-word;
}

#termsAndConditionsContainer .section_terms {
    background: #F6FDFF;
    padding: 20px;
    border-radius: 11px;
    margin-bottom: 10px;
    text-align: center;
}

#termsAndConditionsContainer .section_terms i {
    margin-right: 4px;
    margin-left: 4px;
}

#termsAndConditionsContainer .terms_content p {
    font-size: 14px;
    text-align: justify;
    word-wrap: break-word;
}

@media (max-device-width : 560px) {
    #termsAndConditionsContainer .angelaiDisclaimer p {
        margin-bottom: 20px !important;
    }

}

@media (max-width:768px) {
    #termsAndConditionsContainer .hero_image {
        height: 30vh !important;
        margin: 85px 10px 10px !important;
    }
}

@media (max-width:1024px) {
    #termsAndConditionsContainer .terms_content {
        margin-bottom: 10px;
    }

    #termsAndConditionsContainer .terms_content h3 {
        margin-bottom: 5px;
    }
}