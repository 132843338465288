.truapppage {
    font-family: 'Poppins';
}

/* .limitationspara {
    text-transform: uppercase;
} */

.truapp {
    background-image: url("https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/Images/truapprovalimage1backgroundimage.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.truapprow {
    text-align: justify;
    font-family: 'Poppins';
    width: 100%;
    padding-top: 12rem;
}

.truappheading {
    color: #FFF;
    font-size: 45px;
    font-weight: 800;
}

.truapppara1 {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 400;
    width: 80%;
    margin-top: 5rem;
}

.truapppara2 {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 400;
    width: 80%;
    margin-top: 3rem;
    margin-bottom: 6rem;
}

.truappimage {
    width: 95%;
    margin-bottom: 8rem;
}

.truappwork {
    padding-top: 5rem;
}

.truapprowwork {
    text-align: justify;
    padding-bottom: 5rem;
}

.truappworkimage {
    width: 86%;
}

.truappheadingwork {
    color: #78301D;
    font-weight: 700;
    font-size: 2rem;
    padding-top: 1rem;
    margin-bottom: 1.5rem;
}

.trulistItem {
    color: #0D1C2F;
    list-style-type: none;
    margin-right: 10px;
    margin-bottom: 1rem;
    font-size: 1.3rem;
    margin-left: 2rem;
}

/* .trulistIcon {
    position: absolute;
    z-index: -2;
    left: 0;
    margin-top: 7px;
    margin-left: -7.5rem;
    width: 100%;
    height: 20px;
    background-image: url("https://resources.swmc.com/swmc-images/trueterms/listimageslack.png");
    background-size: contain;
    background-repeat: no-repeat;
} */
.trulistIcon{
    margin-left: -11rem;
    position: absolute;
}
.truappworkimage{
    position: relative;
    z-index: 1;
}

.truapproworange {
    background-color: #953900;
    border-radius: 10px;
    box-shadow: 0px 4px 29px #00000040;
    padding-left: 5rem;
    position: relative;
    z-index: 5;
}

.truappheadingorange {
    color: #FFFFFF;
    font-size: 2.2rem;
    margin-top: 3rem;
}

.truapphr {
    background-color: #e4da00;
    height: 2px;
    width: 70%;
    margin-bottom: 2rem;
}

.truorangelistItem {
    color: #FFFFFF;
    list-style-type: none;
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    margin-left: 27px;
}

.truorangeIcon {
    position: absolute;
    margin-top: 8px;
    width: 100%;
    height: 30px;
    background-image: url("https://resources.swmc.com/swmc-images/trueterms/truorangeIcon.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.truapporangeimage {
    width: 90%;
    margin: 2rem;
}

.truappdoanddont {
    background-color: #282828;
    padding-bottom: 6rem;
    padding-top: 15rem;
    position: relative;
    margin-top: -8.5rem;
}

.doanddontheading {
    color: #FFFFFF;
    text-align: center;
    font-size: 28px;
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.doanddontrow {
    background-image: url("https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/Images/doanddonttable.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 4rem;
}

.ksIxFF {
    margin-top: 5rem;
    padding-bottom: 1rem;
    margin-left: 1rem;
}

.ksIxFFnew {
    margin-top: 5rem;
    padding-bottom: 1rem;
    margin-left: 1rem;
}


.lejapX {
    text-align: justify;
    font-size: 1.21rem;
    color: #FFFFFF;
    padding: 0.75rem 0;
}

.trutermsrow {
    background-image: url("https://resources.swmc.com/swmc-images/trueterms/termsbackground.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.truliabilitycap {
    margin-bottom: 4rem;
}

.cappara {
    text-align: center;
    margin-bottom: 2rem;
}

.truliabilitycappara {
    font-family: 'Poppins';
    text-align: justify;
    color: #000000;
    font-size: 1.2rem;
}

.truliabilitycappara a {
    color: #78301D; 
    word-wrap: break-word;   
}

.truliabilitycappara a:hover {
    color: #78301D;
    text-decoration: underline;
}

.capparaliability {
    color: #78301D;
    font-size: 1.5rem;
    font-weight: bold;
    padding-left: 0.75rem;
}

@media only screen and (min-width: 768px) and (max-width: 991.8px) {
    .truapp {
        background-size: 100% 80% !important;
    }

    .truapprow {
        padding-top: 9rem !important;
    }

    .truappheading {
        font-size: 25px !important;
    }

    .truapppara1 {
        font-size: 12px !important;
        margin-top: 2rem !important;
    }

    .truapppara2 {
        font-size: 12px !important;
        margin-top: 2rem !important;
    }

    .truappimage {
        width: 120% !important;
        margin-bottom: 6rem;
    }

    .truappheadingwork {
        font-size: 1.5rem !important;
    }

    .truappwork {
        margin-top: -6rem !important;
    }

    .trulistItem {
        font-size: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }

    .truappworkimage {
        margin-top: 4rem !important;
        width: 95% !important;
        margin-left: -1rem !important;
    }

    .truappheadingorange {
        font-size: 1.4rem !important;
        margin-top: 1rem !important;
    }

    .truapphr {
        width: 95%;
    }

    .truorangelistItem {
        margin-bottom: 0.5rem !important;
        font-size: 1rem !important;
    }

    .truorangeIcon {
        margin-top: 2px !important;
        height: 20px;
        margin-left: -1.8rem;
    }

    .doanddontrow {
        background-size: 100% 80% !important;
    }

    .doanddontheading {
        width: 100% !important;
    }

    .lejapX {
        font-size: 0.75rem !important;
    }

    .trutermheading {
        font-size: 1.1rem !important;
        margin-top: -0.5rem !important;
    }

    .trulistitem {
        font-size: 0.9rem !important;
    }

    .trulist1item {
        font-size: 0.8rem !important;
    }

    .truliabilitycappara {
        font-size: 0.9rem !important;
    }

    .doanddontheading {
        font-size: 22px !important;
    }

    .doanddontrow {
        margin-top: 1rem !important;
    }

    .truappdoanddont {
        padding-top: 12rem !important;
    }

    .truappdoanddont {
        padding-bottom: 4rem !important;
    }

    .ksIxFF {
        margin-top: 6rem;
        padding-bottom: 2.5rem;
        width: 45%;
        margin-left: -0.5rem;
    }

    .ksIxFFnew {
        margin-top: 6rem;
        padding-bottom: 2.5rem;
        width: 45%;
        margin-left: -1.5rem;
    }

    .truliabilitycappara {
        font-size: 1.17rem !important;
    }
}
@media only screen and (min-width: 992px){
    .truorangeIcon{
        margin-left: -2.5rem;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.8px) {
    .truappheading {
        font-size: 30px !important;
    }

    .truapppara1 {
        font-size: 15px !important;
    }

    .truapppara2 {
        font-size: 15px !important;
    }

    .truappimage {
        width: 100% !important;
        margin-bottom: 6rem;
    }

    .trulistItem {
        font-size: 1rem !important;
    }

    .truappworkimage {
        margin-top: 4rem !important;
        width: 96% !important;
    }

    .truappheadingorange {
        font-size: 2rem !important;
        margin-top: 2rem !important;
    }

    .truapphr {
        width: 95%;
    }

    .truorangelistItem {
        margin-bottom: 0.5rem !important;
        font-size: 1.5rem !important;
    }

    .truorangeIcon {
        margin-top: 3px !important;
    }

    .doanddontheading {
        width: 100% !important;
    }

    .lejapX {
        font-size: 1.1rem !important;
    }

    .trutermheading {
        font-size: 1.5rem !important;
    }

    .trulistitem {
        font-size: 0.9rem !important;
    }

    .trulist1item {
        font-size: 0.8rem !important;
    }

    .truliabilitycappara {
        font-size: 1rem !important;
    }

    .truliabilitycappara {
        font-size: 1.17rem !important;
    }
}
@media only screen and (min-width: 1200px) {
    .truappworkimage{
        margin-top: 4rem;
    }
    
}

@media only screen and (min-width: 1200px) and (max-width: 1399.8px) {
    .truappheading {
        font-size: 35px !important;
    }

    .truapppara1 {
        font-size: 17px !important;
    }

    .truapppara2 {
        font-size: 17px !important;
    }

    .truappimage {
        width: 100% !important;
        margin-bottom: 6rem;
    }

    .truapprowwork {
        margin-top: -4rem;
    }

    .truappheadingwork {
        margin-top: 4rem;
    }

    .trulistItem {
        font-size: 1.1rem !important;
    }

    .truappworkimage {
        margin-top: 6rem !important;
    }

    .truappheadingorange {
        font-size: 2rem !important;
        margin-top: 2rem !important;
    }

    .truapphr {
        width: 75%;
    }

    .truorangelistItem {
        margin-bottom: 0.75rem !important;
        font-size: 1.5rem !important;
    }

    .truorangeIcon {
        margin-top: 3px !important;
    }

    .doanddontheading {
        width: 100% !important;
    }

    .lejapX {
        font-size: 1.1rem !important;
    }
}


@media only screen and (min-width: 320px) and (max-width: 767.8px) {
    .truappimage {
        display: none;
    }

    .truappworkimage {
        display: none;
    }

    .truappdoanddont {
        display: none;
    }

    .truapp {
        background-image: url("https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/Images/truapprovalimagemobile1backgroundimage.webp");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
    }

    .truappimagemobile {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .truapprow {
        padding-top: 9rem;
        padding-bottom: 15px;
        display: block;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .truappheading {
        color: #fff;
        font-size: 27px !important;
        margin-top: 2rem !important;
        font-weight: 900 !important;
    }

    .truapppara1 {
        font-size: 16px !important;
        text-align: justify !important;
        width: 100% !important;
        margin-top: 3rem !important;
    }

    .truapppara2 {
        font-size: 16px !important;
        text-align: justify !important;
        width: 100% !important;
    }

    .truappwork {
        padding-top: 3rem !important;
    }

    .truapprowwork {
        display: block;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .truappworkimagemobile {
        width: 100%;
        margin-top: -3rem;
    }

    .truappheadingwork {
        font-size: 1.55rem !important;
    }

    .trulistItem {
        font-size: 1.2rem !important;
        font-weight: 500;
    }

    .truapproworange {
        margin-top: 2rem;
        border-radius: 0px;
        padding-left: 0 !important;
    }

    .truappcolumnorange {
        padding-left: 3rem;
    }

    .truappheadingorange {
        font-size: 1.8rem;
        text-align: justify;
        margin-top: 2rem !important;
    }

    .truapphr {
        width: 87%;
    }

    .truorangelistItem {
        font-size: 1.3rem;
        text-align: justify;
    }

    .truorangeIcon {
        margin-top: 2px !important;
        width: 50%;
        left: 4rem;
    }

    .truapporangeimage {
        margin: 0 !important;
        width: 100% !important;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
    }

    .lejapX {
        width: 150%;
        margin-left: -1rem;
        text-align: justify;
        hyphens: auto;
    }

    .trutermsrow {
        background-image: none;
    }

    .trutermheading {
        font-size: 1.60rem;
        margin-top: -2rem !important;
    }

    .trulistitem {
        font-size: 1.15rem;
        font-family: 'Poppins';
    }

    .trulist1item {
        font-size: 1.05rem;
        font-family: 'Poppins';
    }

    .truliabilitycap {
        margin-top: -3rem;
    }

    .cappara {
        text-align: justify;
    }

    .truappdoanddontmobile {
        background-color: #282828;
    }

    .truappdoanddontrowmobile {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .doanddontheadingmobile {
        color: #FFFFFF;
        text-align: center;
        font-size: 1.3rem !important;
        padding-top: 2.5rem;
    }

    .lejapXmobile {
        color: #FFFFFF;
        text-align: justify;
        font-size: 20px;
    }

    .dotextwithimage{
        margin-top: -7.5rem;
        padding-left: 1.5rem;
    }

    .donttextwithimage{
        margin-top: -7rem;
        padding-left: 1.5rem;
    }

    .doanddontcomparison{
        margin-top: -1rem;
        margin-bottom: 3rem;
    }

    .ksIxFFmobile1 {
        text-align: justify;
        padding-top: 5rem;
        padding-bottom: 1rem;
        background-image: url("../Images//domobiletablenew.png"); 
        background-size: 95% 90%;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 2rem;
        margin-bottom: -2rem;
    }

    .ksIxFFmobile2 {
        text-align: justify;
        padding-top: 5rem;
        padding-bottom: 1rem;
        background-image: url("../Images/domobiletablenew.png"); 
        background-size: 95% 90%;
        background-repeat: no-repeat;
        background-position: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .lejapXmobile {
        text-align: justify;
        font-size: 1.3rem !important;
        color: #FFFFFF;
        padding: 1rem 1rem;
    }

    .truliabilitycappara {
        font-size: 1.17rem !important;
    }

}

@media only screen and (min-width: 767.8px) {
    .truappimagemobile {
        display: none;
    }

    .truappworkimagemobile {
        display: none;
    }

    .truappdoanddontmobile {
        display: none;
    }

}

@media only screen and (min-width: 320px) and (max-width: 389.7px) {
    .truappheading {
        font-size: 20px !important;
    }

    .truappheadingwork {
        font-size: 1.3rem !important;
    }

    .trulistItem {
        font-size: 1rem !important;
    }

    .truappheadingorange {
        font-size: 1.5rem !important;
    }

    .truorangelistItem {
        font-size: 1.1rem !important;
    }

    .truorangeIcon {
        margin-top: -2px !important;
        left: 4rem !important;
    }

    
    .truliabilitycappara {
        font-size: 1.17rem !important;
    }

    .trutermheading {
        font-size: 1.5rem !important;
    }

    .capparaliability {
        font-size: 1.26rem !important;
    }
}
@media screen and (max-width: 600px) {
    .truapp{
        padding-bottom: 4rem;
    }
    
}