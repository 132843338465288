.page_container a:hover{
    text-decoration: none;
    
  }
  .Property-Insurance-section{
    border-bottom: 3px solid #E67B27;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
  
  .header_border{
    height: 3px;
    background: #00aae4;
    width: 100px;
    }
  
    .hero_image1 {
      /* margin-top:65px; */
      background-image: url("https://prod-cdn.swmc.com/mortgagepossible/Images/property-banner.webp");
      height: 50vh;
      background-position: right;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      border-radius: 10px;
      z-index: 2;
      margin-top: 6rem;
    }
    .hero_image1::before{
      content: '';
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#98daf0+0,98daf0+100&1+0,0+100 */
      background: -moz-linear-gradient(273deg, rgba(207, 137, 102, 0.6) 1.74%, rgba(124, 35, 6, 0.60) 98.79%) !important; /* FF3.6-15 */
      background: -webkit-linear-gradient(273deg, rgba(235, 110, 46, 0.60) 1.74%, rgba(124, 35, 6, 0.60) 98.79%) !important; /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(273deg, rgba(235, 110, 46, 0.60) 1.74%, rgba(124, 35, 6, 0.60) 98.79%) !important;
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#98daf0', endColorstr='#0098daf0',GradientType=1 ); /* IE6-9 */
      position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;
        right: 0;
        top: 0;
        border-radius: 10px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .hero_image1 p{
        width: 50%;
        z-index: 2;
        position: relative;
        font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    }
  
    .hero_image1 h1{
      color: #fff;
      font-size: 120px;
      /* font-weight: bold; */
      line-height: .9;
      z-index: 2;
      position: relative;
      font-family: 'Lucky Fellas' ;
      text-align: center;
  }
  .site_header_lines {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: calc(50% - 680px);
    width: calc(50% + 680px);
    /* background: url('https://resources.swmc.com/swmc-images/MortgageeClauses/header-lines-7fa67e5cc6.svg') top left no-repeat; */
    background-size: 3536px auto;
    opacity: .1;
    mix-blend-mode: multiply;
    pointer-events: none;
  }
  
  .other_subpageheader {
    background-image: url('https://resources.swmc.com/swmc-images/Contact+us/contactusbanner.jpg');
    background-position: right center;
    background-size: 80%;
    background-repeat: no-repeat;
    min-height: 500px;
  }
  
  .contentsection h5{
    font-weight: 600;
    color: #8A3D24;
    width: 230px;
  }
  .contentsection p{
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 0px;
    color: #282828;
    max-width: 300px;
  }
  .contentsection{
    margin-top: 20px;
  }
  .homePageCards{
    /* background-color: #ececec; */
    /* box-shadow: 0px 1px 9px -2px #d2d2d2; */
    background: url('https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/Images/union.webp');
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 10px;
  min-height: 340px;
  text-align: left;
  margin: 10px 0;
  background-size: 100% 100%;
  position: relative;
  }
  /* .homePageCards:hover{
   transform: scale(1.1);
  } */
  .imgsec{
    position: absolute;
    right: 3%;
    top: 1%;
  }
  .homepageImg{
    width: 85px;
    padding: 10px;    
  }
    
  .locationCards{
    min-height: 250px;
  background: #fff;
  width: 100%;
  /* box-shadow: -2px 0px 12px 1px #6262622b; */
  margin-bottom: 20px;
  margin-right: 20px;
  text-align: center;
  /* cursor: pointer; */
  position: relative;
  padding: 20px;
  border-top: 5px solid #26aae1;
  text-align: left;
  }
  .locationCards p:hover{
    color: #00aae4;
  }
  .locationCards h5{
    font-weight: 600;
  }
  
  .top_container {
    max-width: 600px;
    padding-top: 110px;
    padding-left: 100px;
  }
  
  .top_container h2 {
    font-size: 32px;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #26AAE1;
    letter-spacing: 2px;
    margin-top: 0;
  }
  
  .top_container p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
  }
  
  .top_wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  .lendingImg{
    width: 100%;
  }
  .lendingTextRow{
    padding-top: 20px;
  }
  .wholesaleContainer{
    padding-top: 5%!important;
  }
  
  .pageContainer{
    margin-top:70px;
  }
  .pageContainer p{
    font-size: 15px !important;
    line-height: 24px;
  }
  
  .wholesaleContainer h2{
    color:#000;
  }
  .wholesaleContainer ul li{
    line-height: 32px;
  }
  .guidelineContainer{
    background: #0f5c7a !important;
  color: #fff;
  }
  
  .guidelineContainer a{
    color:#fff;
    text-decoration: underline;
  }
  .applicationContainer{
    background: aliceblue;
  }
  .contactContainer{
    background: #0f5c7a !important;
    color: #fff;
    padding-top: 27px;
    padding-bottom: 27px;
    text-align: center;
  }
  .contactContainer p{
    letter-spacing: 2px;
  }
  .contactButton{
    background: #0481b5;
  color: white;
  text-transform: uppercase;
  border: none;
  
  padding: 15px;
  font-size: 16px;
  font-weight: 100;
  }
  .underwriterContainer{
    background: #f2f2f29e;
  }
  
  @media (min-width: 768px) and (max-width:991px){
    .homepageImg {
      margin-right: -7px;
    }
  }
  
  @media ( max-device-width : 560px ){
    p{
        margin-bottom: 0 !important;
    }  
    .contentsection{
      padding: 5px;
    } 
  .hero_image1{
        margin: 85px 5px 10px;
        height: 20vh;
    }
  
    .homePageCards{
      padding: 10px !important;
      min-height: auto;
    }
  
   
    .homePageCards p{
      line-height: 1.5;
    }
  
  }
  
  @media(max-width:768px){
    .hero_image1 h1{
      font-size: 60px;
    }
    .hero_image1{
     
      height: 20vh;
  }
  
    }
    @media (min-width: 600px) and (max-width:991px){
      .contentsection {
        margin-top: 20px;
        width: 180px;
    
      }
    }
    @media (min-width: 992px) and (max-width:1199px){
      .contentsection {
        margin-top: 20px;
        width: 180px;
    
      }
      .homePageCards{
        min-height: 300px;
      }
      .homepageImg {
        width: 65px !important;
       }
    }
    
    @media(max-width:1024px){
      .hero_image1{
        height: 20vh;
      }
      }
  @media(min-width:768px) and (max-width:992px){
   
  .homePageCards{  
  min-height: 390px;  
  }  
  }
 
  @media (max-width:1199px){
    .homepageImg {
      width: 65px;
      padding: 10px;
      }
  }
  @media screen and (max-width: 560px)
  {
.hero_image1 {
    height:25vh !important;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}
}

  @media (min-width: 1200px) {
    .contentsection h5 {
        font-weight: 600;
        color: #8A3D24;
        width: 230px;
        font-size: 1.6rem !important;
        margin-top: 0rem !important;
    }
    .homePageCards{
        min-height: 320px !important;
    }
    .contentsection p{
        font-size: 18px;
    }
    .page_container{
      margin-top: 2rem;
    }
  }