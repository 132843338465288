.webinar-heading{    
color: #F0B533;
font-family: Poppins;
font-size: 2rem;
font-style: normal;
font-weight: 600;
line-height: 1.5 ;
}
.supercharge a{
    text-decoration: none;
    color: #FFF;
}
.supercharge a:hover{
    text-decoration: underline;
}
.justin-contact-info a{
    text-decoration: none;
    color: #282828;
}
.justin-contact-info a:hover{
    text-decoration: underline;
}
.supercharge-form{
    position: relative;
  }
.brown{
    color: #E67B27;
}
.yellow{
    color: #F0B533;
}
.bannerHeading1{
    color: #FFF;
font-family: Poppins;
font-size: 3rem;
font-style: normal;
font-weight: 700;
line-height: 1.5;
}
.webinar-info{
    color: #FFF;
text-align: justify;
font-family: Poppins;
font-size: 1rem;

}
.justin-contact-info{ 
    padding: 20px;
    color: #282828;
    font-weight: 600;
    line-height: 1.2 !important;   
    margin-left: 8rem;
    word-wrap: break-word;
}
.justin-potrait{
    max-width: 100%;
    width: 200px;
    margin-left: -5rem;
    position: absolute;
    left: 0;
    margin-top: -1rem;
}
.justin-block p{
margin-bottom: 5px;
}
.justin-block{
    background-color: #F0B533;
    border-radius: 10px;
    position: relative;
    margin-top: 3rem;
    
}
.justin-name{
    font-size: 2rem;
    font-weight: 700;
}

.webinar-date p{
    color: #282828;
    font-family: Poppins;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
    margin-left: 5rem;
    padding-top: 10px;
}

@media screen and (min-width: 992px) {
    .webinar-info{
        width: 430px;
    }

    .supercharge{
        background-image: url("https://prod-cdn.swmc.com/mortgagepossible/assets/desktop-bg.webp");
        padding-bottom: 3rem;
        background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    }
    .brown{
        min-height: 250px;
    }
    .webinar-info-row{
        margin-top: 3rem;
    }
    .justin-block{
        display: flex;
    }
    .bannerHeading1{
        margin-top: 7rem;
    }
    .supercharge .supercharge .bannerBackground{
        padding-bottom: 5rem;
    }
    .webinar-heading{
        margin-top: 3rem;
        margin-bottom: 2rem;
    }
    .webinar-date{
        background-image: url(https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/Images/date-and-time.webp);
        background-repeat: no-repeat;
        background-size: 70% 100%;
        height: 80px;
        margin-bottom: 2rem;
    }
    
}

@media screen and (min-width: 992px) and (max-width: 1199px){
    .bannerHeading1{
        font-size: 2.5rem;
    }
    .webinar-date{
        background-size: 100% 100% !important;
    }
    .supercharge .bannerBackground {
        height: 903px;
    }
    .webinar-info{
        width: 365px;
        margin-top: 2rem;
    }
    .justin-block{
        margin-top: 8rem;
    }
    .justin-potrait{
        margin-top: 0rem;
    }
}
@media screen and (min-width: 1200px) {
.top-section{
    left: 50%;
    transform: translate(-50%, 0rem);
}
.supercharge .bannerBackgroundImage{
    height: 54rem;
}
.supercharge .supercharge .bannerBackground{
    padding-bottom: 0rem;
}
  }
@media screen and (min-width: 1200px) and (max-width: 1399px){

  .webinar-date{
    background-size: 80% 100%;
  }
}

@media screen and (max-width: 991px){
    .top-section{
        left: 50%;
        transform: translate(-50%, 0rem);
    }
    .watch-webinar-btn-div{
        text-align: center;
        margin-top: 1rem;
    }
    .webinar-date{
        background-image: url(https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/Images/date-and-time.webp);
        background-repeat: no-repeat;
        background-size: 106% 100%;
        height: 80px;
        margin-top: 2rem;
    }
    .yellowdiv{
        height: 290px;
        background-color: #F0B533;
        margin-top: -18rem;
    }
    .supercharge .bannerBackgroundImage {
        width: 100%;
        height: auto;
        margin-top: 50rem;
        display: none;
    }
    .supercharge{
      background-image: url("https://prod-cdn.swmc.com/mortgagepossible/assets/mobile-bg.webp");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
    
    .bannerHeading1{
        font-size: 8vw;
        margin-top: 6rem;
    }
    .webinar-heading{
        font-size: 1.5rem;
        text-align: center;
    }
    .justin-potrait{
        left: 50%;
        transform: translate(-50%, 10px);
        margin-top: -8rem;
        margin-left: 0rem;
    }
    .justin-block{
        margin-top: 9rem;
        text-align: center;
    }
    .justin-contact-info{
        margin-left: 0rem;
        padding-top: 6rem;
        font-size: 16px;
    }
    .justin-name{
        font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1rem !important;
    }
    .webinar-date p{
      font-size: 1rem;
      margin-left: 4rem;
      padding-top: 14px;
    }
   }

   @media screen and (min-width: 600px) and (max-width: 991px) {
    .bannerHeading1{
        font-size: 6vw !important;
    }
  
    .justin-contact-info{
        font-size: 1.2rem;
    }
    .webinar-info{
        font-size: 1.3rem;
    }
    .webinar-date{
        height: 107px;
    }
    .webinar-date p{
        padding-top: 14px;
        font-size: 1.5rem;
        margin-left: 7.5rem;
    }
   }
   @media screen and (max-width: 359px) {
   .webinar-date p{
    font-size: 16px;
    padding-top: 7px;
   }
}
@media screen and (min-width: 460px) and (max-width: 600px) {
    .webinar-date p{
     font-size: 1.2rem;
   margin-left: 7rem;
    }
 }

 @media screen and (min-width: 355px) and (max-width:365px){
    .webinar-date p{
        font-size: 1rem !important;
        padding-top: 5px !important;
    }
 }

 .watch-webinar-btn {
    background: linear-gradient(180deg, #F0B533 0%, #EB6E2E 100%);
    border: 1.448px solid #FFF;
    border-radius: 8px;
    color: #282828!important;
    font-size: 24px;
    font-weight: 700;
    padding: 12px 24px;
    text-align: center;
    display: inline-block;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
}

.watch-webinar-btn:hover {
    background: linear-gradient(180deg, #F0B533 0%, #EB6E2E 100%);
    color: #fff!important;
    font-weight: 500!important;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
}

.watch-webinar-btn:active {
    background: linear-gradient(180deg, #F0B533 0%, #EB6E2E 100%);
    box-shadow: inset 0px 3px 4px rgba(0, 0, 0, 0.2);
}

.supercharge:hover a{
    text-decoration: none;
}