/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */
@font-face {
    font-family: 'Lucky Fellas';
    src: url('../src/components/fonts/LuckyFellas.woff2')format('woff2'), url('../src/components/fonts/LuckyFellas.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.website-container-padding {
    padding-right: 1rem;
    padding-left: 1rem;
}

.container-bg {
    background-color: #FCFCF9;
    overflow-x: hidden;
}

.loadingSpinner {
    margin-top: 5rem;;
}
 .route-margin {
background-color: #303030 !important;
 }
.hero_image {
    height: 50vh;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    /* margin: 100px 0 20px 0; */
    border-radius: 10px;
    z-index: 2;
}

.hero_image::before {
    content: '';
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#98daf0+0,98daf0+100&1+0,0+100 */
    background: -moz-linear-gradient(left, #953900, rgba(152, 218, 240, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #953900, rgba(152, 218, 240, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #953900, rgba(152, 218, 240, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#98daf0', endColorstr='#0098daf0', GradientType=1);
    /* IE6-9 */
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    right: 0;
    top: 0;
    border-radius: 10px;
}

.hero_image p {
    width: 50%;
    z-index: 2;
    position: relative;
    font-size: 14px;
}

.hero_image h1 {
    color: #fff;
    font-size: 120px;
    line-height: .9;
    z-index: 2;
    position: relative;
    font-family: 'Lucky Fellas';
}

@media screen and (max-width: 560px ) {
    .hero_image {
        margin: 85px 5px 10px !important;
        height: 20vh !important;
    }
}

@media screen and (max-width: 768px) {
    .hero_image {
        margin: 85px 10px 10px;
        height: 30vh;
    }
    

    .hero_image h1 {
        font-size: 60px;
    }
}
@media screen and (max-width: 359px) {
  .footer-side2 p {
    font-size: 14px !important;
    line-height: 1.7;
}
}



@media screen and (min-width: 768px) {
    .ms-desktop-3 {
        margin-left: 1.5rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199.7px) {
  .route-margin {
    padding-top: 4rem !important;
  }
    .hero_image {
      margin: 85px 5px 10px;
      height: 30vh;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1440.7px) {
    .ms-desktop-3 {
        margin-left: 3rem;
    }
}

.document-container{
    margin-top: 6rem;
}
.document-container {
    width: 100%;
    max-width: 100%;
    overflow: hidden; /* Optional: Prevent horizontal overflow of the document container */
  }
  
  .html-content {
    max-width: 100%;
    width: 100%;
    overflow: auto; /* Allow vertical scrolling for the entire content */
  }
  
  .html-content table {
    width: 100%; /* Ensure the table expands to fit its container */
    max-width: 100%; /* Prevent table from overflowing its container */
    overflow-x: auto; /* Enable horizontal scrolling for the table on smaller screens */
    border-collapse: collapse; /* Optional: Style for table */
    border: 2px solid #ddd; /* Optional: Style for table */
  }
  
  @media (min-width: 768px) {
    .html-content {
      overflow: hidden; /* Disable horizontal scrolling for larger screens */
    }
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 100%;
    max-height: 90%;
    overflow-y: auto;
  }
  
  .close-button {
    background: #f44336;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    float: right;
  }
  .modal-content .table-wrapper{
    overflow-y: scroll;
  }
  .html-content h3, .modal-content h3{
    background-color: #E67B27;
    padding: 10px;
    color: #fff;
  }
  
  .guide-container{
    margin-top: 10rem;
  }
  .document-container{
    display: flex;
    padding: 20px;
  }
  .document-title h2, .document-title h3, .document-title h4{
    font-size: 1.2rem;
    cursor: pointer;
    padding: 6px;
    border-radius: 10px;
  }
  .document-title h3:hover, .document-title h4:hover{
    background-color: #eeebd4;
   
  }
  .document-program p{
    cursor: pointer;
  }
  .document-program p:hover{
    text-decoration: underline;
  }
  .document-title h2{
    background-color: #E67B27;
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
    color: #ffff;
  }
  .html-content table h2, .modal-content .table-wrapper h2{
    font-size: 1rem !important;
  }
  .html-content table th, .modal-content .table-wrapper th{
    font-weight: normal !important;
  }
  @media screen and (max-width: 991.9px) {
    .route-margin {
      padding-top: 4rem;
    }
  }
  @media screen and (min-width: 992px) {
    .route-margin {
      padding-top: 6rem;
    }
   .document-title{
    width: 500px;
    padding: 20px;
    border-radius: 10px;
    border : 1px solid #aaa;
    margin-right: 1rem;
   }
    
  }


