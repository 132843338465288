.navbar {
  z-index: 999;
  box-shadow: none;
  height: 70px !important; 
  transition: all 0.3s ease-in;
  top: 0; /* Initially, set the top position to 0 */
}  

.navbar.active {
  background: #3C3C3C !important;
}   
.navbar-dark .navbar-nav {
  justify-content: space-around;
}  
.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
}  

.nav-link:hover
 {
  color: #f26f24 !important;
}

.navbar-collapse {
  background-color: transparent;
}  
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.8rem;
  line-height: 1;
  background-color: transparent;
  border: 0px solid transparent;
  border-radius: 0rem;
  fill: #ffb600;
}  
@media only screen and (max-width: 5120.98px) {
  .navbar-dark .navbar-nav .nav-link {
    margin: 0rem 0 0rem 0rem;
  }
}  
@media only screen and (max-width: 599px) {
  .navbar-collapse{
      width: 100% !important;
  }
  #menuNavbar .navbar-nav .dropdown-menu {
 
    height: 200px;
    overflow-y: scroll;
  }
} 

@media only screen and (max-width: 350px) {
.main_logo{
  width: 150px !important;
}
.navbar-toggler-icon{
  width: 25px;
}
}   

@media only screen and (max-width: 991.97px) and (max-width: 1199.97px) {
  .navbar {
    width: 100%;
    transition: all 0.5s ease-in;
  }  
 
  .navbar-dark .navbar-nav .nav-link {
    margin: 0 1rem;
  }

  .navbar-nav .ms-left {
    padding-right: 5rem !important;
  }
}


@media only screen and (max-width: 991.97px) {
  
.navbar-container{
  margin-top: -10px;
}
 
.navbarTransition {
  transform: translateY(-950%) !important;
  transition: all .7s ease-in;
}
.main_logo{
  width: 180px;
} 

  /* Define the CSS class for sliding in the navbar */
  .slide-in {
    animation: slideIn 0.5s ease-in ;
  }

  @keyframes slideIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

      .bg-light {
    background-color: transparent !important;
  }

  .navbar-collapse {
    padding: 1rem 1rem 1rem;
    text-align: left;
    background-color: #ffffff;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    top: 100%;
    margin-top: 2px;
    border-radius: 5px;
    border: 1px solid #f26f24;
   
     }

  .navbar-dark .navbar-nav .nav-link {
    color: #000;
  }
 
   
  .navbar-dark .navbar-nav .nav-link {
    margin: 0;
    font-size: 1rem !important;
  }

  .navbar .btn-outline-warning {
    margin-top: 0.5rem;
  }
} 

@media screen and (min-width: 992px) {
 
  .main_logo {
    width: 200px;
    margin-left: auto !important;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
    margin-left: -4rem;
}
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    justify-content: flex-end;
    
  }  
  }

@media only screen and (min-width: 320px) and (max-width: 358.7px) {
 
  .main_logo{     
    margin-top: 4px;
  }
}

.login_modal {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  width: 90%;
  max-width: 500px; /* Adjust the max width as needed */
  padding: 5px;
  border-radius: 10px;
}

.login_modal_content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.close_button {
position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 18px;
  color: #000;
}

.login_modal_context {
  margin-bottom: 20px;
}

.login_modal_buttons {
  display: flex;
  justify-content: space-between;
}

.No_btn,
.Yes_btn {
  width: 45%;
}
@media only screen and (min-width: 992px) {
  .mobile-AccessibilityMenu-show {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .desktop-AccessibilityMenu-show {
    display: none;
  }
  .mobile-AccessibilityMenu-show {
    position: absolute;
    right: 0rem;
    top: 0rem;
    z-index: 2;
  }
}