/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
.hero_image {
    background-image: url("https://resources.swmc.com/swmc-images/txDisBanner.jpeg");
}
.tx-dis{
    border-bottom: 3px solid #E67B27;
}

#texasDisclosureContainer h4,
#texasDisclosureContainer p {
    font-family: Poppins;
    font-style: normal;
    line-height: 1.2;
    color: #383839;
    text-align: justify;
 
}

#texasDisclosureContainer a {
    text-decoration: none;
}

#texasDisclosureContainer a:hover {
    text-decoration: underline;
}