/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
 .mobile-container-products{
    border-bottom: 3px solid #E67B27;
    margin-bottom: 3rem;
 }
.products-row {
    justify-content: space-around;
    line-height: 1.75;
    color: #282828;
}

.products-section-head {
    margin-top: 3rem;
    color: #EE5B29;
    font-weight: 600;
}

.products-container {
    position: relative;
}

.gradient-1 {
    width: 100px;
    height: 300px;
    flex-shrink: 0;
    background-color: #E67B27;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 2rem;
    filter: blur(145.004944px);
}

.gradient-2 {
    width: 100px;
    height: 300px;
    flex-shrink: 0;
    background-color: #E67B27;
    position: absolute;
    left: 0;
    bottom: 0;
    filter: blur(145.004944px);
}