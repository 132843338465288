.card-images{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    max-width: 100%;
}
.footernote{
  margin-top: 3rem;
  color: #343434;
  font-size: 14px;
  text-align: justify;
}
.footernote a{
  color: #343434;
  text-decoration: none;
}
.footernote a:hover{
  text-decoration: underline;
}
.card-title{
    color: #EB6E2E;
    font-weight: 600;
}
.learn-more-things a, .notes a{
  text-decoration: none;
  color: #000;
}
.notes a{
  color: #fff !important;
}
.learn-more-things a:hover, .notes a:hover{
  text-decoration: underline;
}
.body-section{

padding-bottom: 2rem;
}
.quality-cards{
    height: 250px;
    text-align: center;
    width: 98%;
    margin-left: 3px;
}
.gradient-border{
    background: linear-gradient(
        to bottom, 
        rgba(40, 40, 40, 0.5), 
        rgba(243, 236, 212, 0.5), 
        #F0B533
      );
      height: 255px;
      width: 100%;
      padding-top: 2px;
    border-radius: 10px;
  
}
.learn-more-things{
    background-image: url("https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/Images/mortgagepossible-watermark.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
}
.learn-more-things::before {
    content: "";
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translateX(-50%);
    width: 150px; /* width of the centered border */
    border-top: 4px solid #8C412D; /* border properties */
  }
.ask-simpers-mobile{
    max-width: 100%;
    position: relative;
}
.learn-more-things-button{
    border-radius: 10px;
background: #8C412D;
color: #fff;
padding: 15px;
justify-content: center;
align-items: center;
gap: 15px;
font-size: 1.5rem;
font-style: normal;
font-weight: 600;
display: block;
margin-left: auto;
margin-right: auto;
border: none !important;
}
.learn-more-things-button:hover{
    background-color: #D37327 ;
}
@media screen and (min-width: 600px) {
    .mobile-note-section{
        display: flex;
        justify-content: center;
    }
    .learn-more-things{
        height: 600px;
    margin-top: 3rem;

    }
    .notes{
        display: flex;  
        align-items: center; 
        margin-left: -3rem;     
    }
    .note-image{
        width: 200px;
        height: 120px;
        margin: 2rem auto;
        margin-left: -3rem;       
    }
    .note-image img{
        display: block;
        margin: 1.2rem auto;
        float: right;
        padding-right: 10px;
    }
    .note-content{
        width: 200px;
        height: 120px;
        border-radius: 0 30px 0 0;
        color: #fff;
        text-align: center;
        padding: 10px;
    }
    .note-heading-mob{
     font-size: 1.2rem;
     font-weight: 600;
     margin-bottom: 2px !important;
    }
    .learn-more-things p{
        width: 300px;
        text-align: justify;
        display: block;
        margin: 8rem auto 1rem;
        font-size: 22px;
    }    
}
@media screen and (min-width: 1200px) {
    .note-heading-mob{
        font-size: 1.6rem;       
       }
          
}
@media screen and (min-width: 1200px) and (max-width: 1399px) {
 .mob-flex{
    margin-top: 2rem;
 }          
}
@media screen and (min-width: 340px) and (max-width: 991px) {
 .mobile-align{
  margin-top: 1rem;
 }         
 }
@media screen and (min-width: 1400px) {
.mob-flex{
    margin-top: 3rem;
}
          
}
@media screen and (max-width: 599px) {
  .mobile-note-section{
    display: flex;
    flex-wrap: wrap-reverse;
    margin-top: 1rem;
  }
  .learn-more-things::before{
    top: 27% ;
  }
  .mobile-link{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .notes{
    text-align: center;
    padding: 10px;
    color: #fff;
    display: flex;
  }
  .mob-flex{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
  .quality-cards{
    height: auto;
    margin-left: 4px;
  }
  .gradient-border{
    height: auto;
    margin-top: -1px;
  }
  .note-image{
    padding: 10px;
  }
  .note-content{
    padding: 5px;
    border-radius: 0px 20px 20px 0;
    width: 100%;
  }
  .ask-simpers-mobile{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .note-heading-mob{
    font-size: 1.5rem;     
    font-weight: 600;  
   }
   .mobile-column-reverse{
flex-direction: column-reverse;
   }
  /* .mob-flex{
    display: flex;
  } */
   .learn-more-things{
    height: 600px;
   }
   .learn-more-things p{
    width: 100%;
    padding: 20px;
    text-align: justify;
    font-size: 1.2rem;
    color: #000;
    margin-top: 10rem;
   }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .quality-cards{
        height: 350px;
    }
    .gradient-border{
        height: 353px;
    }
    .mob-flex{
      margin-top: 4rem;
    }

}
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .quality-cards{
        height: 270px;
    }
    .gradient-border{
        height: 276px;
    }
    .notes{
        margin-left: -5rem;
    }
    .note-content{
        width: 160px;
    }
}