.cut-off-banner{
    
    background-image: url(https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/assets/desktop-bg.webp);
    padding-top: 2rem;
    
    background-size: 100% 68%;
    background-repeat: no-repeat;
}
@media screen and (min-width: 1200px) {
    .cut-off-banner-container, .pageContainer{
        width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    }
}

.cut-off-note{
    font-size: 1.2rem;
    line-height: 1.7;
}
.cut-off-image{
    background-image: url(https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/Images/cut-off-image.webp);
}
.cut-off-section{
    border-bottom: 3px solid #E67B27;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
}
