.title-underwriter-section a{
    color: rgba(0, 0, 0, 1);
}
.title-underwriter-section  .table-responsive {
    border-radius: 10px !important;
    border: 1px solid #FFB48C !important;
    margin-bottom: 2rem;
  }
.title-underwriter-section .hero_image1{
    background-image: url("https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/Images/title-master-banner.webp") !important;
}
.title-underwriter-section .notes-part{
    color: #282828;
    font-size: 12px;
}
.title-underwriter-section{
    border-bottom: 3px solid #E67B27;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

@media screen and (max-width: 768px) {
    .title-underwriter-section .hero_image1 h1{
       font-size: 3.2rem !important;
    }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .title-underwriter-section .hero_image1 h1{
       font-size: 3.5rem !important;
    }
}