/**
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/
.form-container {
  padding: 1rem;
  /* background: transparent; */
}
.event-details-div{
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #083f88;
  border-radius: 5px;
  padding: 15px;
  width: 250px;
  font-size: 14px;
  padding-bottom: 2px;
  color: #083f88;
}
#signupForm .form-control {
border-color: #EEAF32;
}

.formWrapper {
  border-radius: 10px;
  background: #282828;
  padding: 4rem 1rem;
  border: 1px solid #EEAF32;
  margin-top: 6rem;
}
#signupForm ::placeholder {
  color: #FFF !important;
}
.pad-form {
  width: 80%;
  display: block;
  margin-left: auto;
}
.formHeader h6 {
  font-size: 1rem;
  font-weight: 500;
  color: white;
  text-align: center;
}
.formHeader {
  padding: 1rem 0 0 !important;
}
.formHeader h3 {
  font-size: 25px;
  font-weight: 500;
  color: #EEAF32;
  text-align: center;
}
#signupForm h6 {
  margin: 0;
  padding: 8px 0;
}
#signupForm .form-group {
  padding: 0 0 8px 0;
}
#signupForm .form-control:focus {
  border-color: #2ba8e0;
}
#signupForm .text-blue {
  color: #00208b;
  text-align: center;
  margin: 0;
  padding: 8px 0;
}
#signupForm .consent-font {
  text-align: justify;
  margin-bottom: 0;
  padding-bottom: 8px;
  font-size: 11px;
  color: #FFF;
  font-weight: 400;
  margin-top: 10px;
}
#signupForm .form-check {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0.5rem;
}
#signupForm .form-check-input[type="radio"] {
  border-radius: 0.25em;
  cursor: pointer;
}
#signupForm .form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10l3 3l6-6%27/%3e%3c/svg%3e");
  cursor: pointer;
}
#signupForm .form-check-label {
  padding: 0 1.5rem 0 0;
  font-weight: 500;
  cursor: pointer;
}
.thanktext {
  color: #083f88 !important;
  font-size: 1.5rem;
}
#signupForm .form-link {
  text-decoration: underline;
  color: #FFF !important;
}
#signupForm .form-link:hover {
  text-decoration: underline;
  color: #00aae4;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  #signupForm .btn-submit {
    font-size: 0.9rem !important;
  }
}
@media screen and (min-width: 600px) and (max-width: 991.9px) {
  .pad-form {
    width: 550px;
  }
}
#signupForm .btn-submit {
  color:  #282828;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px auto 0px;
  border: none;
  border-radius: 10.817px;
  background: #EEAF32;
  text-align: center;
  font-weight: 600;
  font-size: 1.10rem !important;
}
#signupForm .btn-submit:hover {
  background-color: #00aae4;
  color: #fff;
}
#signupForm .blg-row-2 {
  margin-top: -8px;
  justify-content: center;
}
#signupForm img.blg-img {
  width: 65px;
  height: 65px;
  padding: 0;
  margin: 0 12px;
}
#signupForm h4 {
  color: #00208b;
}
#signupForm .btn-start,
#signupForm .btn-blg {
  margin: 8px 0;
  font-weight: 700;
}
#signupForm .btn-blg:hover {
  color: #ffffff;
}
#signupForm .btn-start {
  background-color: #083f88;
  border-color: #083f88;
  color: #ffffff;
}
#signupForm .btn-start:hover {
  background-color: #2ba8e0;
  border-color: #2ba8e0;
  color: #ffffff;
}
#signupForm .divflex {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
#signupForm .backBtnDiv {
  align-self: flex-start;
}
#signupForm .continueBtnDiv {
  position: absolute;
}
#signupForm #showConsentText {
  display: inline-block;
}
#signupForm #hideConsentText {
  display: inline-block;
}
#signupForm .consentSmall {
  cursor: pointer;
  color: #FFF;
}
#signupForm .consentLink {
  cursor: pointer;
  padding: 8px 0px;
  font-size: 0.7rem;
}
#signupForm .btn-success {
  cursor: pointer;
  background-color: #FFF !important;
  color: #EEAF32 !important;
  margin-left: 2rem;
}
#signupForm .btn-success:hover {
  cursor: pointer;
  background-color: #EEAF32 !important;
  color: #fff !important;
}
#signupForm .consentLink:hover {
  text-decoration: underline;
  color: #00aae4;
}
#signupForm .whiteText {
  color: #ffffff;
}
@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .pad-form {
    width: 91% !important;
    max-width: 545px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 991.9px) {
  .pad-form {
    width: 96% !important;
    max-width: 725px !important;
  }
}
@media screen and (max-width: 350px) {
  #signupForm .btnAdjustDiv {
    left: 35% !important;
  }
}
@media screen and (max-width: 991px) {
  .event-details-div{
    margin: 1rem auto;
    padding-bottom: 10px;
  }
  .pad-form {
    width: 100%;
    margin-top: 1rem;
    margin-left: auto;
    /* border: 1px solid #FFF; */
    margin-right: auto;
    /* margin-bottom: 3rem; */
  }
  .formWrapper {
    padding: 0rem 0rem !important;
  }
  .formHeader h3 {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  .formHeader h6 {
    font-size: 1.2rem !important;
  }
  #signupForm .form-control {
    font-size: 0.8rem;
    background: rgba(255, 255, 255, 0);
    color: #FFF !important;
  }
  .photoConsent {
    font-size: 10px !important;
    padding: 0 !important;
    color: #fff;
    font-weight: 400 !important;
    line-height: 1.3;
    text-align: justify;
    hyphens: auto;
  }
  .photoConsentLink {
    font-size: 10px !important;
  }
  .photoConsentLink:hover {
    cursor: pointer;
    color: #00aae4;
    text-decoration: underline;
  }
  .signupformconsent{
    margin-left: 25px !important;
  }
}
@media screen and (min-width: 1400px) and (max-width: 1599px) {
  .pad-form {
    width: 80% !important;
  }
}
@media screen and (min-width: 992px) {
  #signupForm .form-control {
    font-size: 0.8rem;
    background: rgba(255, 255, 255, 0);
    color: #FFF !important;
  }
  .formWrapper{
    right: 3%;
   position: absolute;
  }
  .formWrapper {
    padding: 0rem 1rem;
  }
  .photoConsent {
    font-size: 10px !important;
    padding: 0 !important;
    color: #fff;
    font-weight: 400 !important;
    line-height: 1.3;
    text-align: justify;
    hyphens: auto;
  }
  .photoConsentLink {
    font-size: 10px !important;
  }
  .photoConsentLink:hover {
    cursor: pointer;
    color: #00aae4;
    text-decoration: underline;
  }
}
@media screen and (max-width: 416px) {
  #signupForm .consentLink {
    font-size: 0.625rem;
  }
  #signupForm .btnAdjustDiv {
    left: 30%;
  }
}
@media screen and (max-width: 499px) {
  .thanktext {
    color: #083f88 !important;
    font-size: 1rem;
  }
  #signupForm .btn-submit {
    font-size: 0.9rem !important;
  }
  #signupForm .btn-success{
margin-left: 3rem !important;
  }

}

.inside-placeholder{
  color: #ffffff !important;
}

.mp-logo-div{
  background-color: #000000;
  border-radius: 10px;
  max-width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
   margin-top: 1rem
}
.mp-logo-img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
 
}