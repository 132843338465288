

@media screen and (min-width:992px) {
.submission-checklist-lists{
    width: 510px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 3;
}
.submission-checklist-lists a{
    color: #000;
}
.submission-checklist-section{
    width: 600px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 240, 232, 1);
    padding: 20px;
    border-radius: 20px;
}

.submission-checklist-section h2{
    text-align: center;
    width: 350px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -3rem;
    background: rgba(255, 146, 88, 1);
    border-radius: 10px;
    padding: 10px;
    font-size: 1.7rem;
    border: 5px solid #fff;
    margin-bottom: 2rem;
}
.document-templates-section h2{
    text-align: center;
    width: 350px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    background: rgba(255, 146, 88, 1);
    border-radius: 10px;
    padding: 10px;
    font-size: 1.7rem;
    border: 5px solid #fff;
    margin-bottom: -2rem;
    position: relative;
}
.document-templates-row{
    background: rgba(255, 240, 232, 1);
    padding: 20px;
    line-height: 3;
    border-radius: 20px;
    padding-top: 4rem;
}
.document-templates-section a{
    color: #000;
}
}

@media screen and (max-width: 991px) {
    .submission-checklist-lists li{
        margin-bottom: 20px;
        line-height: 1.7;
    }
    .submission-checklist-lists a{
        color: #000;
    }
    .submission-checklist-section{
        background: rgba(255, 240, 232, 1);
        padding: 20px;
        border-radius: 20px;
    }
    
    .submission-checklist-section h2{
        text-align: center;
        margin-top: -3rem;
        background: rgba(255, 146, 88, 1);
        border-radius: 10px;
        padding: 10px;
        font-size: 1.7rem;
        border: 5px solid #fff;
        margin-bottom: 2rem;
    }
    .document-templates-section h2{
        text-align: center;
        margin-top: 2rem;
        background: rgba(255, 146, 88, 1);
        border-radius: 10px;
        padding: 10px;
        font-size: 1.7rem;
        border: 5px solid #fff;
        margin-bottom: -2rem;
        position: relative;
    }
    .document-templates-row{
        background: rgba(255, 240, 232, 1);
        padding: 20px;
      
        border-radius: 20px;
        padding-top: 4rem;
    }
    .document-templates-row ul li{
        margin-bottom: 20px;
    }
    .document-templates-section a{
        color: #000;
    }    
}