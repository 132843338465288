.angelai-training-images{
    max-width: 100%;
    height: auto;
    border-radius: 15px;
    margin: auto 10px;
    width: 95%;
    cursor: pointer;
    border: 1px solid #000;
}
.thumbnail-section{
    position: relative;
}
.slick-slider{
    -webkit-tap-highlight-color: transparent !important;
}
/* Remove focus outlines from all elements inside the slick carousel */
.slick-slide *,
.slick-slide:focus {
    outline: none !important;
    box-shadow: none !important;
  
}

/* Also target iframe and image elements directly */
.slick-slide iframe
{
    outline: none !important;
    box-shadow: none !important;
   
}


.training-videos{
   
    padding: 1px 0 1rem 0;    
}
.video-carousel-heading{
    font-size: 40px;
    color: #FF9B00;
    font-weight: 500;
    text-align: center;
    margin-top: 3rem;
}
.video-carousel-subheading{
    font-size: 20px;
    color: #fff;
}
.playbutton{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -1rem);
    cursor: pointer;
}
.arrows{
    cursor: pointer;
}

@media screen and (min-width: 1200px) and (max-width: 1599px) {
    
    .angelai-training-images{
        height: 250px;
    }
}
@media screen and (min-width: 1600px) and (max-width: 1999px) {
    
    .angelai-training-images{
        height: 300px;
    }
}
@media screen and (min-width: 500px) and (max-width: 600px) {
    
    .angelai-training-images{
        width: 350px !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (min-width: 2000px) {
    
    .angelai-training-images{
        height: 450px;
    }
    .playbutton {      
        transform: translate(-50%, -3rem) !important;      
        width: 100px;
    }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .angelai-training-images{
        height: 200px;
    }
}
@media screen and (min-width: 360px) and (max-width: 991px) {
    .angelai-training-images{
        width: 350px;
        height: 200px !important;
    }
}
@media screen and (max-width: 991px) {
    
  /* This class will create the overlay for the video when it's playing */
.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
    z-index: 10; /* Overlay above iframe */
    cursor: pointer; /* Indicates the overlay is clickable */
}

.video-slide {
    position: relative; /* Make the iframe and overlay stack on top of each other */
}

.angelai-training-images {
    width: 100%;
    height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

    .angelai-training-images{
        width: 90%;
    }
    .video-carousel-heading{
        font-size: 31px !important;
    }
    .video-carousel-subheading{
        font-size: 18px !important;
    }
}
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light gray background */
    border-top: 4px solid #000; /* White spinner color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}