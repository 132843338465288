.document-list {

  padding: 20px;

}

.product-name {
  font-weight: 600
}

.svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
  float: right;
}

.product-name {
  cursor: pointer;
}

.selected-product {
  color: #E67B27;
  /* Change the color to your preferred style */
  text-decoration: underline;
}



.document-content {
  flex: 2;
  padding: 20px;
}

.document-title,
.document-heading,
.document-product {
  cursor: pointer;
  margin-bottom: 10px;
}

.html-content {
  padding: 20px;
  border: 1px solid #aaa;
  border-radius: 5px;
  margin-left: -2rem;
  text-align: justify;
  height: 1000px;
  /* Adjust or remove height restriction */
  overflow: scroll;
  /* Use auto instead of scroll for dynamic scrollbar */
}

.guidelines-page .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10001;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  overflow-y: auto;
  border-radius: 8px;
}

.document-heading {
  margin-left: 8px;
}

.document-program.selected p {
  text-decoration: underline;
  color: #E67B27;
  font-weight: 600;
}

.close-button-top {
  position: fixed;
  top: 4%;
  right: 3%;
  background: lightgray;
  border: none;
  font-size: 19px;
  cursor: pointer;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 0;
  z-index: 3;
}

.modal-content {
  font-size: 11px;
}

.product-heading {
  margin-top: 7rem;
  font-weight: 600;
  color: #8C412D;
}

.document-viewer-container {
  border-bottom: 3px solid #E67B27;
}
@media screen and (max-width: 991px) {
 .guidelines-page .close-button-top{
  right: 0% !important;
 }
}
@media screen and (min-width: 992px) {
  .document-viewer-container {
    display: flex;
    padding-bottom: 2rem;

  }
}
@media only screen and (min-width:992px) and (max-width:1308px) {
.html-content{
 
  overflow: scroll !important;
 
}
}

@media only screen and (min-width:992px) and (max-width:1308px) {
.html-content{ 
  overflow: scroll !important; 
}
}

@media only screen and (min-width:992px) and (max-width:1199px) {
  .document-list {
    width: 400px;
  }

   .html-content{
    width: 650px !important;
      
  }


  .document-title {
    width: 350px;
  }
}

@media only screen and (min-width:770px) and (max-width:992px) {
  .close-button-top {
    right: 4%;
  }
}

@media only screen and (min-width:1200px) {
  .document-product h4 {
    font-size: 18px;
  }
}
@media only screen and (min-width:1200px) and (max-width: 1299px){
 .html-content{
  width: 650px !important;
 }
}
@media only screen and (min-width:1300px) and (max-width: 1399px){
  .html-content{
   width: 750px !important;
  }
 }

@media only screen and (min-width:400px) and (max-width:420px) {
  .document-title h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width:420px) {
  .title-icon {
    margin-top: -10px;
  }
}

@media only screen and (min-width:360px) and (max-width:420px) {
  .document-product h4 {
    font-size: 1.1rem !important;
  }
}

.document-product h4 {
  margin-left: 10px;
}

.document-program {
  margin-left: 13px;
}
