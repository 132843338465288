/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
.license_image {
    background-image: url("https://resources.swmc.com/swmc-images/Licenses/licenses-banner.jpg") !important;
}
.disclaimer-content{
    border-bottom: 3px solid #E67B27;
}

.license_content p,
.stateLicenseInfo p,
.affilationInfo p {
    font-family: Poppins;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    color: #383839;
    text-align: justify;
    
}

.stateLicenseInfo {
    margin-top: 3rem;
}

.googleMapsUrl,
.swmcPhoneNumber {
    text-decoration: none;
}

.googleMapsUrl:hover,
.swmcPhoneNumber:hover {
    text-decoration: underline;
}

.swmcPhoneNumber {
    color: #383839 !important;
}

@media screen and (max-width: 768px) {
    .stateLicenseInfo {
        margin-top: 1rem;
    }

    .license_content p,
    .stateLicenseInfo p,
    .affilationInfo p {
        font-size: 16px;
    }
}

@media screen and (min-width: 1200px) {
  .disclaimer-content{
    margin-top: 6rem;
  }
}