.nav-tabs .nav-link.active {
    background-color: #FF691A;
   
}
.turn-time-section{
    border-bottom: 3px solid #E67B27;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
}
.hero_image h1 {
    color: #fff;
    font-size: 120px;
    line-height: .9;
    z-index: 2;
    position: relative;
    font-family: 'Lucky Fellas';
}
.turn-time-commitment-banner .hero_image::before{
    background: none !important;
}

.turn-time-commitment-banner{
    background-image: url(https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/assets/desktop-bg.webp);
    padding-top: 2rem;    
    background-size: 100% 68%;
    background-repeat: no-repeat;
}
.turn-time-commitment-image{
    background-image: url(https://d2w24n4g34usfg.cloudfront.net/mortgagepossible/Images/turn-times-banner.webp);
   background-size: 100% 100%;
   background-repeat: no-repeat;
}
.cut-off-link{
    margin: 2rem auto;
}
.cut-off-link a{
    color: #000;
    font-size: 1.3rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.cut-off-link a:hover{
    color: #000;
}
.tabs-note{
    color: #939393;
}
.nav-tabs .nav-link {
    background-color: #000;
    color: #fff;
}
.nav-tabs .nav-link:hover{
color: #fff !important;
}
.nav-tabs{
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 767px) {
    .nav-tabs{
        background-color: #000;
        border-bottom: 1px solid;
    }
    .nav-tabs .nav-link.active {
        background-color: #FF691A;
        border: none;
    }
}

