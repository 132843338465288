/* th {
    background: #eeebd4;
  } */

.accordion{
  --bs-accordion-active-bg: #E67B27 !important;
  --bs-accordion-active-color: #fff !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
}

.tablebody .accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
    text-align: justify;  
    word-break: normal;
}
  
  .hero-image1:before {
    background: linear-gradient(90deg, #953900, rgba(152, 218, 240, 0));
    border-radius: 10px;
    bottom: 0;
    content: "";
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#98daf0", endColorstr="#0098daf0", GradientType=1);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  
  }
  
  @font-face {
    font-family: 'Lucky-Fellas';
    src: local('Lucky-Fellas'), url(../fonts/LuckyFellas.woff2) format('truetype');
    font-display: swap;
  }
  .hero-image1 h1 {
    color: #fff;
    font-size: 6rem;
    line-height: .9;
    line-height: 1.25;
    position: relative;
    z-index: 2;
    font-family: 'Lucky-Fellas';
  }
  
  @media only screen and (max-width: 575.97px) {
    .hero-image1 h1 {
      font-size: 3.2rem;
      line-height: 1.25;
      font-family: 'Lucky Fellas';
    }
  }
  
  #termsnavbar .morganText {
    margin-top: -1rem;
  }
  

  .accordianhead{
    font-weight: 600;
     }

 
  @media screen and (min-width: 992px) {
   
    .mainpage-footer {
      padding-top: 16rem;
      margin-top: -17rem;
      background-color: #0b1237;
    }

    th, td {
      padding: 8px;
      border: 2px solid #eeebd4;
      text-align: left;
    }
  
    .hero-image1 {
      background-image: url("https://resources.swmc.com/swmc-images/PrivacyPolicy/banner.jfif");
      background-position: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 10px;
      color: #fff;
    
      display: flex;
      flex-direction: column;
      height: 40vh;
      justify-content: center;
      margin: 6rem 20px 20px;
      padding: 20px;
      position: relative;
    
    }
    .factjsteble td{
     
      padding: 15px !important;
    }
    .whatdoes{
      font-weight: 500;
    }
    .hero-image {
      margin: 7rem 20px 20px !important;
    }
  
  
  }
  
  .starttavle{
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .question{
    margin-bottom: 2rem;
  }
  
  @media only screen and (max-width: 499px) {
    .mainpage-footer {
      margin-top: 5rem;
    }
  
    .terms-footer {
      margin-top: 6rem !important;
      padding-top: 0rem !important;
    }
  }

  @media only screen and (max-width: 991px) {
    @font-face {
        font-family: 'Lucky Fellas';
        src: url('../../components/fonts/LuckyFellas.woff2')format('woff2'), url('../../components/fonts/LuckyFellas.woff') format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
  .hero-image1 h1 {
   
    font-family: 'Lucky-Fellas';
  }
 .tablescroll {
overflow-x: auto;
  }

  th, td {
    padding: 8px;
    border: 1px solid #dddddd;
    text-align: justify;
  }
  /* .question td{
    width: 28%;
  } */
 




  .hero-image1 {
    background-image: url("https://resources.swmc.com/swmc-images/PrivacyPolicy/banner.jfif");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    color: #fff;
  
    display: flex;
    flex-direction: column;
    height: 40vh;
    justify-content: center;
    margin: 6rem 20px 20px;
    padding: 20px;
    position: relative;
  
  }
.tablefact{
  overflow-x: auto;
}
}

.table-container {
  overflow-x: auto;
}
.questioncelli{
  margin-bottom: 1rem;
}

#terms-wrapper1 a{
  text-decoration: none;
}
#terms-wrapper1 a:hover{
  text-decoration: underline;
}


/* .question  tbody > tr > td{
  padding:15px !important;
  vertical-align: middle;
} */
.question {
  width: 100%; /* Set the width to 100% */
}

@media screen and (min-width: 320px) and (max-width:414px) {
  .question {
    width: 200%;
}
.question td{
 
 
  font-size: 13px!important;
  text-align: justify!important;
}

.starttavle td{
  font-size: 13px!important;
  text-align: justify!important;
}
.question th{
 
  padding:15px !important;
  font-size: 13px!important;
  text-align: justify!important;
}
.starttavle {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width :200%;
  overflow: auto;
}


}